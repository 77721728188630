import React from 'react';
import { CopyrightOutlined } from '@ant-design/icons';
import { Card, Carousel, Col, Image, Layout, Row } from 'antd';
import './App.css';
import logo from './assets/drawing.svg';
import shot1 from './assets/shot_stats_framed.png';
import shot2 from './assets/shot_calendar.png';
import shot3 from './assets/shot_statspage.png';
import shot4 from './assets/shot_enter_resistance.png';
import logoBig from './assets/solotracker_icon_192x192.png';
import goog from './assets/en_badge_web_generic.png';

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'


const { Header, Footer, Content } = Layout;

const IMGWIDTH = 200;

const App = () => (

    <Layout>
      <Header style={{ background: 'white', height: 70, paddingBottom: '80px', paddingTop: '20px' }}>

        <Row justify={'space-around'} align={'middle'}>
          <Col xl={12} style={{ textAlign: 'left' }}>
            <Image src={logo}
                   preview={false}
                   height={52}
                   width={341}
            />
          </Col>
          <Col xl={12} style={{ textAlign: 'right' }}>
            <a
              href='https://play.google.com/store/apps/details?id=com.solotracker.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <Image
                alt='Get it on Google Play'
                src={goog}
                preview={false}
                height={50}
                width={167}
              /></a>
          </Col>
        </Row>

      </Header>
      <Content>
        <div style={{ height: '100px' }}/>
        <Row justify={'center'}>
          <Col xl={12} style={{ textAlign: 'center' }}>
            <Image src={logoBig}
                   width={150}
                   height={150}
                   preview={false}
            />
          </Col>
        </Row>
        <br/>
        <br/>
        <Row justify={'center'}>
          <Col style={{ padding: 10 }}>
            <h1>
              Track your data points to keep healthy and fit.
            </h1>
          </Col>
        </Row>

        <div style={{ height: '50px' }}/>

        <Row justify={'center'}
             style={{ background: '#ffffff' }}
        >
          <Col xl={6}>
            <Card
              style={{
                border: 'none'
              }}
            >
              <p>By tracking the important data points, solotracker allows you to visualize your progress.</p>

              <p>With solotracker you can track:
                <ul>
                  <li>How much cardio you are getting every day</li>
                  <li>Your resistance workouts, for example see how much more weight you can lift</li>
                </ul>
              </p>

              <p>
                We are adding new data point types all the time, check back soon!
              </p>

              <p>
                You can also try a web version <a href={"https://app.solotracker.com"} target={"_blank"}>here.</a>
              </p>
            </Card>
          </Col>
          <Col xl={6}
               style={{
                 textAlign: 'center',
                 paddingTop: '10px'
               }}>
            <Carousel dotPosition={'bottom'} autoplay={false}>
              <div>
                <Image
                  src={shot1}
                  width={IMGWIDTH}
                  preview={false}
                />
              </div>
              <div>
                <Image
                  src={shot2}
                  width={IMGWIDTH}
                  preview={false}
                />
              </div>
              <div>
                <Image
                  src={shot3}
                  width={IMGWIDTH}
                  preview={false}
                />
              </div>
              <div>
                <Image
                  src={shot4}
                  width={IMGWIDTH}
                  preview={false}
                />
              </div>
            </Carousel>

          </Col>

        </Row>

      </Content>
      <Footer>
        <CopyrightOutlined/> 2021 solotracker
      </Footer>
    </Layout>
  )
;

export default App;
